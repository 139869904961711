import { Grid } from '@chakra-ui/react';
import LoginImage from './LoginImage';

export default function LoginLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <Grid
      gridTemplateColumns={{ base: '1fr', lg: 'repeat(2, 1fr)' }}
      h={'100vh'}
      overflow='hidden'
    >
      <LoginImage />

      {children}
    </Grid>
  );
}
