import { Card, Divider, Heading, Image, Stack, Text } from '@chakra-ui/react';
import Link from 'next/link';
import { FiBox, FiBriefcase } from 'react-icons/fi';
import LoginLayout from '@/components/login/LoginLayout';

export default function Login() {
  return (
    <Stack h='full' p='8' alignItems='center'>
      <Image src='/images/brand-logo.png' alt='Procura Logo' h='14' />
      <Heading size='lg' mt='8' mb='4' fontWeight='semibold' color='gray.800'>
        Hello User!
      </Heading>
      <Text
        fontSize='md'
        fontWeight='medium'
        textAlign='center'
        color='gray.500'
      >
        Let&apos;s get you logged into your
        <br />
        Procura Account.
      </Text>

      <Card
        as={Stack}
        w='full'
        gap='0'
        p='0'
        maxW='sm'
        mt='8'
        borderTop='2px solid'
        borderTopColor='gray.50'
        rounded='lg'
        overflow='hidden'
        boxShadow='md'
      >
        <Stack
          as={Link}
          href='/login/client'
          color='gray.500'
          transition='all 0.25s'
          textAlign='center'
          alignItems='center'
          w='full'
          p='8'
          borderBottomColor='transparent'
          _hover={{
            bg: 'gray.100',
            outlineColor: 'procura-blue',
            color: 'procura-blue',
          }}
        >
          <Text fontSize='4xl'>
            <FiBriefcase />
          </Text>
          <Heading size='sm' fontWeight='semibold'>
            I am a Client
          </Heading>
        </Stack>

        <Divider borderColor='gray.300' />

        <Stack
          as={Link}
          href='/login/admin'
          color='gray.500'
          transition='all 0.25s'
          textAlign='center'
          alignItems='center'
          w='full'
          p='8'
          borderTopColor='transparent'
          _hover={{
            bg: 'gray.100',
            outlineColor: 'procura-blue',
            color: 'procura-blue',
          }}
        >
          <Text fontSize='4xl'>
            <FiBox />
          </Text>
          <Heading size='sm' fontWeight='semibold'>
            I am an Admin
          </Heading>
        </Stack>
      </Card>
    </Stack>
  );
}

Login.getLayout = page => <LoginLayout>{page}</LoginLayout>;
