import { Show, Stack, Box, Heading } from '@chakra-ui/react';
import { useRouter } from 'next/router';

export default function LoginImage() {
  const router = useRouter();

  return (
    <Show above='lg'>
      <Stack
        h='100%'
        backgroundImage={`url(${'/images/stock1.jpg'})`}
        backgroundSize='cover'
        backgroundPosition='center'
        backgroundRepeat='no-repeat'
      >
        <Box
          h='full'
          bg='linear-gradient(to bottom, rgba(0,0,0,0.75), transparent)'
          p='16'
          color='white'
        >
          {router.pathname === '/client' ? (
            <Heading size='xl' letterSpacing='wider'>
              Your Procurement.
              <br />
              Our Responsibility.
            </Heading>
          ) : (
            <Heading size='xl' letterSpacing='wider'>
              All Procura.
              <br />
              One Platform.
            </Heading>
          )}
        </Box>
      </Stack>
    </Show>
  );
}
